import React from 'react';
import {Link} from "react-router-dom";


const SideNav = () => {
	return (
		<nav aria-label="nav">
			<div
				className="shadow-xl h-20 top-0 md:relative md:h-screen z-10 w-full md:w-48 content-center">
				<div
					className="md:mt-12 md:w-48 md:fixed md:left-0 md:top-0 content-center md:content-start text-left justify-between">
					<ul className="list-reset flex flex-row md:flex-col pt-3 md:py-3 px-1 md:px-2 text-center md:text-left text-xl text-gray-300 hover:text-white">
						<li className="mr-3 flex-1">
							<Link to='/'
								  className="block hover:bg-gray-800 shadow rounded-lg md:rounded-none px-2 py-1 md:py-3 pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-blue-500">
                                    Home
							</Link>
						</li>
						<li className="mr-3 flex-1">
							<Link to='/inventory'
								  className="block hover:bg-gray-800 shadow rounded-lg lg:rounded-none px-2 py-1 md:py-3 pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-green-500">
                                    Inventory
							</Link>
						</li>
						<li className="mr-3 flex-1">
							<Link to='/outgoing'
								  className="block hover:bg-gray-800 shadow rounded-lg lg:rounded-none px-2 py-1 md:py-3 pl-0 md:pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-teal-500">
                                    Outgoing
							</Link>
						</li>
						<li className="mr-3 flex-1">
							<Link to='/incoming'
								  className="block hover:bg-gray-800 shadow rounded-lg lg:rounded-none px-2 py-1 md:py-3 pl-0 md:pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-red-500">
                                    Incoming
							</Link>
						</li>
						<li className="mr-3 flex-1 hidden md:block">
							<Link to='/clients'
								  className="block hover:bg-gray-800 shadow rounded-lg lg:rounded-none px-2 py-1 md:py-3 pl-0 md:pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-yellow-500">
                                    Clients
							</Link>
						</li>
						<li className="mr-3 flex-1 hidden md:block">
							<Link to='/reports'
								  className="block hover:bg-gray-800 shadow rounded-lg lg:rounded-none px-2 py-1 md:py-3 pl-0 md:pl-1 align-middle no-underline border-b-2 border-gray-800 hover:border-orange-500">
                                    Reports
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</nav>

	);
};

export default SideNav;
