import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import './index.css';
import App from './App';
import axios from "axios";
import 'react-toastify/dist/ReactToastify.min.css';
import {ToastContainer} from "react-toastify";

// development / local url
// axios.defaults.baseURL = 'http://localhost:8000/api/';

// production url
axios.defaults.baseURL = 'https://backend.iiacinch.com/api/';
axios.interceptors.request.use(config => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token ? `Token ${token}` : null;
    return config;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ToastContainer hideProgressBar={true} newestOnTop={true}/>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);
