import React from 'react';
import {Link} from "react-router-dom";
import PlusSvg from "./PlusSVG";

const NewLink = ({children}) => {
    return (
        <div className='float-right py-4 mb-2'>
            <Link
                to='new'
                className="px-4 py-2 bg-blue-600 hover:bg-blue-500 text-blue-100 hover:text-white rounded-lg flex items-center font-bold space-x-2 shadow-lg"
            >
                <PlusSvg/>
                <span>{children}</span>
            </Link>
        </div>
    );
};

export default NewLink;
