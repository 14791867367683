import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import errorHandler from "../utilities/error_handler";


const ClientForm = () => {
    const [name, setName] = useState("");
    const [idNo, setIDNo] = useState("");
    const [location, setLocation] = useState("");
    const [redirect, setRedirect] = useState(false);

    // get client id in url params
    let {id} = useParams();

    useEffect(() => {
        if (id) {
            (
                async () => {
                    const {data} = await axios.get(`clients/${id}`);
                    // set data
                    setName(data.name);
                    setIDNo(data.phone);
                    setLocation(data.address);
                }
            )();
        }
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            name,
            phone: idNo,
            address: location,
        }
        try {
            if (id) {
                await axios.put(`clients/${id}/`, data)
                    .then(response => {
                        toast.success("Client updated successfully");
                        setRedirect(true);
                    })
                    .catch(error => errorHandler(error));
            } else {
                await axios.post('clients/', data)
                    .then(response => {
                        toast.success("Client added successfully!");
                        setRedirect(true);
                    })
                    .catch(error => errorHandler(error));
            }

        } catch (err) {
            toast.error("Something went wrong with your request: " + err);
        }
    };

    // check and redirect user to created/updated object
    if (redirect && id) {
        return <Navigate to={`/clients/${id}`}/>
    } else if (redirect) {
        return <Navigate to={`/clients`}/>
    }

    return (
        <>
            <div className="h-screen items-center">
                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-50 text-gray-900 px-3 rounded-xl py-2"
                >
                    <div className="mb-6 flex flex-row items-center space-x-2 px-2 pt-6">
                        <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Client's Full Name:
                        </label>
                        <input
                            onChange={e => setName(e.target.value)}
                            value={name}
                            id='name'
                            type="text"
                            placeholder="Client's Name e.g Holy Dave"
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        />
                    </div>
                    <div className="mb-6 flex flex-row items-center space-x-2 px-2 pt-6">
                        <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Client's ID NO:
                        </label>
                        <input
                            onChange={e => setIDNo(e.target.value)}
                            value={idNo}
                            id='name'
                            type="text"
                            placeholder="ID NO e.g PD435012B"
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        />
                    </div>
                    <div className="mb-6 flex flex-row items-center space-x-2 px-2 pt-6">
                        <label
                            htmlFor="name"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Client's Location
                        </label>
                        <input
                            onChange={e => setLocation(e.target.value)}
                            value={location}
                            id='name'
                            type="text"
                            placeholder="Location e.g HME"
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        />
                    </div>

                    <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                    >
                        Submit
                    </button>

                </form>
            </div>
        </>
    );
};

export default ClientForm;
