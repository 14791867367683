import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Navigate, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import errorHandler from "../utilities/error_handler";


const TransactionForm = (props) => {
    const [quantity, setQuantity] = useState(1);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState(1);
    const [stocks, setStocks] = useState([]);
    const [stock, setStock] = useState(1);
    const [redirect, setRedirect] = useState(false);
    const [success, setSuccess] = useState(false);
    const {pathname} = useLocation();


    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess(true);

        const data = {
            client_id: client,
            stock_id: stock,
            quantity,
        }
        if (pathname.includes('incoming')) {
            data['transaction_type'] = 'Incoming';
        } else if (pathname.includes('outgoing')) {
            data['transaction_type'] = 'Outgoing';
        } else {
            toast.error('An error occurred trying to save your transaction. Please try again!');
            return <Navigate to={'/'}/>
        }

        try {
            await axios.post('transactions/', data)
                .then(response => {
                    toast.success("Transactions saved successfully!");
                    setRedirect(true);
                })
                .catch(error => {
                    errorHandler(error);
                    setSuccess(false);
                })

        } catch (e) {
            toast.error('Something went wrong: ' + e);
            setSuccess(false);
        }
    };
    useEffect(() => {

        // fetch clients
        (
            async () => {
                const {data} = await axios.get('clients/');
                setClients(data);
                setClient(data[0].id);
            }
        )();

        // fetch stocks
        (
            async () => {
                const {data} = await axios.get('stocks/');
                setStocks(data);
                setStock(data[0].id);
            }
        )();

    }, []);


    // check and redirect user to created/updated object(s)
    if (redirect) {
        if (pathname.includes('incoming')) {
            return <Navigate to={`/incoming`}/>
        } else if (pathname.includes('outgoing')) {
            return <Navigate to={`/outgoing`}/>
        } else {
            return <Navigate to={'/'}/>
        }
    }

    return (
        <div className="h-screen items-center">
            <div className="container mx-auto w-full">
                <form
                    onSubmit={handleSubmit}
                    className="bg-gray-50 text-gray-900 px-3 rounded-xl py-2"
                >
                    <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                        <label
                            htmlFor="client"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Select client
                        </label>
                        <select
                            onChange={e => setClient(parseInt(e.target.value))}
                            value={client}
                            id="client"
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        >
                            {clients.map((client) =>
                                <option key={client.phone} value={client.id} className="capitalize">
                                    {client.name?.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}
                                </option>
                            )}
                        </select>
                    </div>

                    <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                        <label
                            htmlFor="stock"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Select stock
                        </label>
                        <select
                            id="stock"
                            value={stock}
                            onChange={e => setStock(parseInt(e.target.value))}
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        >
                            {stocks.map((stock) =>
                                <option
                                    key={stock.id}
                                    value={stock.id}
                                >
                                    {stock.name}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                        <label
                            htmlFor="quantity"
                            className="block mb-2 text-sm font-medium text-gray-900 w-44"
                        >
                            Select stock quantity
                        </label>
                        <input
                            onChange={e => setQuantity(e.target.value)}
                            value={quantity}
                            type="number"
                            className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                            id="quantity"
                            placeholder="Stock quantity"
                            min="1"
                            max="9999"
                        />
                    </div>

                    <button
                        disabled={success}
                        type="submit"
                        className="disabled:opacity-85 text-white sm:w-48 w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                    >
                        Submit
                    </button>

                </form>
            </div>
        </div>
    );
};

export default TransactionForm;
