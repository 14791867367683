import axios from "axios";
import MyDataTable from "../utilities/MyDataTable";
import NewLink from "../utilities/NewLink";
import {useQuery} from "@tanstack/react-query";
import Empty from "../utilities/Empty";
import {format, parseJSON} from "date-fns";
import {BASE_URL} from "../utilities/config";

const Inventory = () => {
    const columns = [
        {
            name: 'Stock Name',
            selector: row => (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`inventory/` + row.id}>{row.name}</a>
            ),
            sortable: true,
        },
        {
            name: 'Quantity',
            selector: row => `${row.quantity} ${row.units}`,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.item_class,
            sortable: true,
        },
        {
            name: 'Last Updated',
            selector: row => row.updated_at,
            format: row => format(parseJSON(row.updated_at), 'PPpp'),
            sortable: true,
        }, {
            name: 'Location',
            selector: row => row.location,
            sortable: true,
        },
    ];

    const {isLoading, data} = useQuery(['inventory'], () => {
        return axios.get(`${BASE_URL}/stocks/`);
    });

    if (isLoading) {
        return (
            <>
                <NewLink>
                    Add New Stock
                </NewLink>
                <div className="mx-auto bg-amber-300 py-12">
                    <Empty>Inventory data is loading...</Empty>
                </div>
            </>
        )
    }

    return (
        <>
            <NewLink>
                Add New Stock
            </NewLink>
            <MyDataTable
                columns={columns}
                data={data.data}
                filter={'name'}
                title={'Stock Inventory List'}
            />
        </>
    );
}

export default Inventory;
