import {useParams, Link} from 'react-router-dom'
import axios from "axios";
import MyDataTable from "../utilities/MyDataTable";
import Empty from "../utilities/Empty";
import {format, parseJSON} from "date-fns";
import {useQuery} from "@tanstack/react-query";

const columns = [
    {
        name: 'Stock',
        selector: row =>
            (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`/inventory/` + row.stock_id}>{row.stock_name}</a>
            ),
        sortable: true,
    },
    {
        name: 'Quantity',
        selector: row => `${row.quantity} ${row.stock_units}`,
        sortable: true,
    },
    {
        name: 'Transaction Type',
        selector: row => row.transaction_type,
        sortable: true,
    },
    {
        name: 'Date & Time',
        selector: row => row.updated_at,
        format: row => format(parseJSON(row.updated_at), 'PPpp'),
        sortable: true,
    },
];


const ClientDetails = () => {
    const {id} = useParams();

    const {isLoading, data} = useQuery(['inventory'], () => {
        return axios.get(`https://backend.iiacinch.com/api/clients/${id}/`);
    });

    if (isLoading) {
        return (
            <Empty>
                Please wait. Client data is loading...
            </Empty>
        )
    }

    return (
        <div className="py-4 bg-gray-500 m-auto h-screen">
            <div className="shadow-lg rounded-2xl p-4 bg-amber-100 max-w-2xl m-auto md:px-10 md:py-12">
                <div className='flex justify-between align-center divide-y'>
                    <h2 className="text-2xl font-bold py-2 capitalize">{data.data.name}</h2>
                    <Link
                        to={`edit`}
                        className='p-3 bg-blue-600 hover:bg-blue-500 text-blue-200 hover:text-blue-100 font-medium rounded-full shadow-md'
                    >
                        Edit client
                    </Link>
                </div>
                <div className="w-full h-full text-center my-2 text-lg font-medium divide-y">
                    <div className="flex justify-between py-2 capitalize">
                        <p>Client's Name: </p>
                        <p>{data.data.name}</p>
                    </div>
                    <div className="flex justify-between py-2">
                        <p>Location: </p>
                        <p className="uppercase">{data.data.address}</p>
                    </div>
                </div>
            </div>
            <div className='py-2 mt-4'>
                {data.data.stock_transactions.length === 0 ?
                    <Empty>This user has no recorded transactions!</Empty>
                    :
                    <MyDataTable
                        columns={columns}
                        data={data.data.stock_transactions}
                        filter={'stock_name'}
                        title={`${data.data.name} Transactions List`}
                    />
                }
            </div>
        </div>
    );
};

export default ClientDetails;
