import {Routes, Route} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import Home from '../src/components/Home';
import Login from './components/dashboard/auth/Login';
import Inventory from "./components/dashboard/inventory/Inventory";
import Incoming from "./components/dashboard/transaction/Incoming";
import Outgoing from "./components/dashboard/transaction/Outgoing";
import Clients from "./components/dashboard/client/Clients";
import Reports from "./components/dashboard/Reports";
import NewIncoming from "./components/dashboard/transaction/New";
import InventoryForm from "./components/dashboard/inventory/InventoryForm";
import StockCard from "./components/dashboard/inventory/StockCard";
import ClientForm from "./components/dashboard/client/ClientForm";
import ClientDetails from "./components/dashboard/client/ClientDetails";
import Layout from "./layouts/Layout";

// create react-query client
const queryClient = new QueryClient()


function App() {
    return (
        <Layout>
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="inventory" element={<Inventory/>}/>
                    <Route path="incoming" element={<Incoming/>}/>
                    <Route path="outgoing" element={<Outgoing/>}/>
                    <Route path="clients" element={<Clients/>}/>
                    <Route path="clients/new" element={<ClientForm/>}/>
                    <Route path="clients/:id" element={<ClientDetails/>}/>
                    <Route path="clients/:id/edit" element={<ClientForm/>}/>
                    <Route path="reports" element={<Reports/>}/>
                    <Route path="incoming/new" element={<NewIncoming/>}/>
                    <Route path="outgoing/new" element={<NewIncoming/>}/>
                    <Route path="inventory/new" element={<InventoryForm/>}/>
                    <Route path="inventory/:id" element={<StockCard/>}/>
                    <Route path="inventory/:id/edit" element={<InventoryForm/>}/>
                </Routes>
            </QueryClientProvider>
        </Layout>
    );
}

export default App;
