import React, {useState} from 'react';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';


const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  background-color: #2979ff;
  border: none;
  color: white;
  padding: 8px 32px 8px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;
// eslint-disable-next-line react/prop-types
const FilterComponent = ({filterText, onFilter, onClear}) => (
    <>
        <TextField
            id="search"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);


export const MyDataTable = (props) => {
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = props.data.filter(
        item => item[props.filter] && item[props.filter].toLowerCase().includes(filterText.toLowerCase()),
    );

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear}
                             filterText={filterText}/>
        );
    }, [filterText, resetPaginationToggle]);

    return (
        <DataTable
            fixedHeader
            fixedHeaderScrollHeight="340px"
            title={props.title}
            columns={props.columns}
            data={filteredItems}
            pagination
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            paginationPerPage={30}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            striped
            responsive
            highlightOnHover
            selectableRows
        />
    );
};

export default MyDataTable;
