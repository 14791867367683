import axios from "axios";
import MyDataTable from "../utilities/MyDataTable";
import NewLink from "../utilities/NewLink";
import {useQuery} from "@tanstack/react-query";
import Empty from "../utilities/Empty";

const columns = [
    {
        name: "Client's Name",
        selector: row =>
            (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`clients/` + row.id}>{row.name}</a>
            ),
        sortable: true,
    },
    {
        name: 'ID No.',
        selector: row => row.phone,
        sortable: true,
    },
    {
        name: 'Location',
        selector: row => row.address,
        sortable: true,
    },
    // {
    //     name: 'Actions',
    // }
];

export const Clients = () => {
    const {isLoading, data} = useQuery(['clients'], () => {
        return axios.get('https://backend.iiacinch.com/api/clients/');
    });

    if (isLoading) {
        return (
            <>
                <NewLink>Add New Client</NewLink>
                <div className="mx-auto bg-gray-700 rounded-lg py-12">
                    <Empty>
                        <p className="py-4 text-xl text-amber-600">Clients data is loading...</p>
                    </Empty>
                </div>
            </>
        )
    }

    return (
        <>
            <NewLink>Add New Client</NewLink>
            <MyDataTable
                columns={columns}
                data={data.data}
                filter={'name'}
                title={'Clients List'}
            />
        </>
    );
};

export default Clients;
