import axios from "axios";
import MyDataTable from "../utilities/MyDataTable";
import NewLink from "../utilities/NewLink";
import {useQuery} from "@tanstack/react-query";
import Empty from "../utilities/Empty";
import {format, parseJSON} from 'date-fns'
import {BASE_URL} from "../utilities/config";


const columns = [
    {
        name: 'Client',
        selector: row =>
            (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`clients/` + row.client_id}>{row.client_name}</a>
            ),
        sortable: true,
    },
    {
        name: 'Stock',
        selector: row =>
            (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`inventory/` + row.stock_id}>{row.stock_name}</a>
            ),
        sortable: true,
    },
    {
        name: 'Quantity',
        selector: row => `${row.quantity} ${row.stock_units}`,
        sortable: true,
    },
    {
        name: 'Date & Time',
        selector: row => row.updated_at,
        format: row => format(parseJSON(row.updated_at), 'PPpp'),
        sortable: true,
    },
];

export const Outgoing = () => {
    const {isLoading, data, isError, error} = useQuery(['outgoing'], () => {
        return axios.get(`${BASE_URL}/transactions/outgoing/`);
    });

    if (isLoading) {
        return (
            <>
                <NewLink>New Outgoing Inventory</NewLink>
                <div className="mx-auto bg-gray-700 py-12 rounded-lg">
                    <Empty>
                        <p className="py-4 text-xl text-amber-600">Outgoing transactions data is loading...</p>
                    </Empty>
                </div>
            </>
        )
    }

    if (isError) {
        return (
            <Empty>
                <p className="text-red-500 hover:text-red-600 text-xl">{error.message}</p>
            </Empty>
        )
    }

    return (
        <>
            <NewLink>New Outgoing Transaction</NewLink>
            <MyDataTable
                columns={columns}
                data={data.data}
                filter={'client_name'}
                title={'Outgoing Stock Transactions List'}
            />
        </>
    );
};

export default Outgoing;
