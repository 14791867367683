import React from 'react';
import SideNav from "./SideNav";
import {Navigate} from "react-router-dom";


const Layout = ({children}) => {
    //problematic code
    /*
    const token = localStorage.getItem('token');

    if (!token) {
        return <Navigate to={'/login'}/>;
    }
    */
    return (
        <main className="bg-gray-900">
            <div className="flex flex-col md:flex-row">
                <SideNav/>
                <section className="w-full">
                    <div className="px-4 py-2 pb-24 md:pb-5">
                        {children}
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Layout;
