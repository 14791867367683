import Layout from "../layouts/Layout";
import {Link} from "react-router-dom";
import axios from "axios";
import Empty from "./dashboard/utilities/Empty";
import {useQuery} from "@tanstack/react-query";
import {format, parseJSON} from "date-fns";

const Home = () => {
    const {isLoading: inventoryLoading, data: inventory, isError: inventoryLoadingError, error: inventoryError} = useQuery(['inventory'], () => {
        return axios.get('https://backend.iiacinch.com/api/stocks/');
    });
    const {isLoading: outgoingLoading, data: outgoing, isError: outgoingLoadingError, error: outgoingError} = useQuery(['outgoing'], () => {
        return axios.get('https://backend.iiacinch.com/api/transactions/outgoing/');
    });
    const {isLoading: incomingLoading, data: incoming, isError: incomingLoadingError, error: incomingError} = useQuery(['incoming'], () => {
        return axios.get('https://backend.iiacinch.com/api/transactions/incoming/');
    });

    return (
        <div className="block rounded-lg shadow-lg bg-white text-center py-3">
            <div
                className="py-2 px-2 md:py-4 lg:py-6 border-b border-gray-300 flex items-center justify-between space-x-2">
                <Link to='/inventory/new'
                      className="p-3 md:py-4 text-green-100 text-sm md:text-xl lg:text-2xl hover:text-white bg-green-600 hover:bg-green-700 rounded-full font-bold shadow-xl uppercase">
                    + New Inventory
                </Link>
                <Link to='/incoming/new'
                      className="p-3 md:py-4 text-blue-100 text-sm md:text-xl lg:text-2xl hover:text-white bg-blue-600 hover:bg-blue-700 rounded-full font-bold shadow-xl uppercase">
                    + New Incoming
                </Link>
                <Link to='/outgoing/new'
                      className="p-3 md:py-4 text-teal-100 text-sm md:text-xl lg:text-2xl hover:text-white bg-teal-600 hover:bg-teal-700 rounded-full font-bold shadow-xl uppercase">
                    + New Outgoing
                </Link>
            </div>
            <div className="block md:flex justify-between mx-2 my-4 p-2 rounded-lg border border-sky-500">
                <div>
                    <h2 className='text-center text-lg md:text-2xl font-bold'>Most Recent Inventory</h2>
                    <hr/>
                    {inventoryLoading ?
                        <Empty>Your inventory data is loading...</Empty>
                        :
                        <div className="flex flex-col-reverse divide-y divide-y-reverse">
                            {inventory.data.slice(0, 5).map(stock =>
                                <div key={stock.id} className="text-left my-2 px-2 md:px-0">
                                    <p className="py-2 md:py-4 text-lg font-semibold">Stock Id: {stock.id}</p>
                                    <p className="py-2 md:py-4 text-sm">Item: {stock.name}</p>
                                    <p className="py-2 md:py-4 text-sm">Quantity: {stock.quantity} {stock.units}</p>
                                    <p className="py-2 md:py-4 text-sm">
                                        Time: <i className="semibold">{format(parseJSON(stock.updated_at), 'PPpp')}</i>
                                    </p>
                                </div>
                            )}
                        </div>
                    }
                </div>
                <div>
                    <h2 className='text-center text-lg md:text-2xl font-bold'>Most Recent Incoming</h2>
                    <hr/>
                    {incomingLoading ?
                        <Empty>Your incoming inventory transactions data is loading...!</Empty>
                        :
                        <div className="flex flex-col-reverse divide-y divide-y-reverse">
                            {incoming.data.slice(0, 5).map(transaction =>
                                <div key={transaction.transaction_no} className="text-left my-2 px-2 md:px-0">
                                    <p className="py-2 md:py-4 text-lg font-semibold">Transaction
                                        No: {transaction.transaction_no}</p>
                                    <p className="py-2 md:py-4 text-sm">Client: {transaction.client_name}</p>
                                    <p className="py-2 md:py-4 text-sm">Item: {transaction.stock_name}</p>
                                    <p className="py-2 md:py-4 text-sm">Quantity: {transaction.quantity} {transaction.stock_units}</p>
                                    <p className="py-2 md:py-4 text-sm">
                                        Time: <i
                                        className="semibold">{format(parseJSON(transaction.updated_at), 'PPpp')}</i>
                                    </p>
                                </div>
                            )}
                        </div>
                    }

                </div>
                <div>
                    <h2 className='text-center text-lg md:text-2xl font-bold'>Most Recent Outgoing</h2>
                    <hr/>
                    {outgoingLoading ?
                        <Empty>Your outgoing inventory transactions data is loading...</Empty>
                        :
                        <div className="flex flex-col-reverse divide-y divide-y-reverse">
                            {outgoing.data.slice(0, 5).map(transaction =>
                                <div key={transaction.transaction_no} className="text-left divide-y my-2 px-2 md:px-0">
                                    <p className="py-2 md:py-4 text-lg font-semibold">Transaction
                                        No: {transaction.transaction_no}</p>
                                    <p className="py-2 md:py-4 text-sm">Client: {transaction.client_name}</p>
                                    <p className="py-2 md:py-4 text-sm">Item: {transaction.stock_name}</p>
                                    <p className="py-2 md:py-4 text-sm">Quantity: {transaction.quantity} {transaction.stock_units}</p>
                                    <p className="py-2 md:py-4 text-sm">Time: <i
                                        className="semibold">{format(parseJSON(transaction.updated_at), 'PPpp')}</i>
                                    </p>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Home;
