import {toast} from "react-toastify";

export default function errorHandler (error) {
    if (error.response) {
        // request was sent but the server responded with status code outside the 2xx range
        let messages = [];
        const errorObject = error.response.data;
        for (let [key, value] of Object.entries(errorObject)) {
            if (Array.isArray(value)) {
                messages = [...value];
            } else {
                messages.push(JSON.stringify(error.response.data[key]));
            }
        }
        for (let i = 0; i < messages.length; i++) {
            toast.error(JSON.stringify(messages[i]));
        }
    } else if (error.message) {
        // error message is available, toast it
        toast.error(JSON.stringify(error.message));
    } else {
        // strange error, toast it
        toast.error(JSON.stringify(error));
    }
}
