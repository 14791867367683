import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import errorHandler from "../utilities/error_handler";
import {toast} from "react-toastify";

const UNITS = [
    "Kilograms",
    "Grams",
    "Tonnes",
    "Sets",
    "Millimeters",
    "Litres",
    "Pieces",
    "Packs",
    "Rolls",
    "Cans",
    "Sheets",
    "Pairs",
    "Milligrams",
    "Bags",
    "Boxes",
    "Cases",
    "Each",
]
const CATEGORIES = ["Tools", "Chemicals", "PPEs", "Equipment", "Others"]


const InventoryForm = (props) => {
    const [name, setName] = useState("");
    const [quantity, setQuantity] = useState("");
    const [units, setUnits] = useState("Kilograms");
    const [item_class, setItemClass] = useState("Tools");
    const [stockID, setStockID] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [location, setLocation] = useState("");

    const {id} = useParams();

    useEffect(() => {
        if (id) {
            (
                async () => {
                    const {data} = await axios.get(`stocks/${id}`);
                    // set data
                    setName(data.name);
                    setQuantity(data.quantity);
                    setUnits(data.units);
                    setItemClass(data.item_class);
                    setStockID(data.id);
                    setLocation(data.location);
                }
            )();
        }
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {
            name,
            quantity,
            units,
            item_class,
            location,
        }
        try {
            if (id) {
                await axios.put(`stocks/${id}/`, data)
                    .then(response => {
                        toast.success("Stock item updated successfully!");
                        setStockID(response.data.id);
                        setRedirect(true);
                    })
                    .catch(error => errorHandler(error));
            } else {
                await axios.post('stocks/', data)
                    .then(response => {
                        toast.success("Stock item added successfully.");
                        setStockID(response.data.id);
                        setRedirect(true);
                    })
                    .catch(error => errorHandler(error));
            }

        } catch (err) {
            toast.error("Unrecognized error: " + err);
        }
    };

    // check and redirect user to created/updated object
    if (redirect) {
        return <Navigate to={`/inventory/${stockID}`}/>
    }

    return (
        <div className="h-screen items-center">
            <form
                onSubmit={handleSubmit}
                className="bg-gray-50 text-gray-900 px-3 rounded-xl py-2"
            >
                <div className="mb-6 flex flex-row items-center space-x-2 px-2 pt-6">
                    <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 w-44"
                    >
                        Stock Name
                    </label>
                    <input
                        onChange={e => setName(e.target.value)}
                        value={name}
                        id='name'
                        type="text"
                        placeholder="Stock name"
                        className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                    />
                </div>

                <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                    <label
                        htmlFor="quantity"
                        className="block mb-2 text-sm font-medium text-gray-900 w-44"
                    >
                        Select stock quantity
                    </label>
                    <input
                        onChange={e => setQuantity(e.target.value)}
                        value={quantity}
                        type="number"
                        className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                        id="quantity"
                        placeholder="Stock quantity"
                        min="1"
                        max="999999999"
                    />
                </div>
                <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                    <label
                        htmlFor="units"
                        className="block mb-2 text-sm font-medium text-gray-900 w-44"
                    >
                        Select stock units
                    </label>
                    <select
                        onChange={e => setUnits(e.target.value)}
                        value={units}
                        id="units"
                        className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                    >
                        {UNITS.map((unit) =>
                            <option key={unit}>{unit}</option>
                        )}
                    </select>
                </div>
                <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                    <label
                        htmlFor="category"
                        className="block mb-2 text-sm font-medium text-gray-900 w-44"
                    >
                        Select stock category
                    </label>
                    <select
                        id="category"
                        onChange={e => setItemClass(e.target.value)}
                        value={item_class}
                        className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                    >
                        {CATEGORIES.map((unit) =>
                            <option key={unit}>{unit}</option>
                        )}
                    </select>
                </div>
                <div className="mb-6 flex flex-row items-center space-x-2 px-2">
                    <label
                        htmlFor="location"
                        className="block mb-2 text-sm font-medium text-gray-900 w-44"
                    >
                        Stock Location
                    </label>
                    <input
                        onChange={e => setLocation(e.target.value)}
                        value={location}
                        id='location'
                        type="text"
                        placeholder="EP05"
                        className="bg-white border border-blue-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-56"
                    />
                </div>

                <button
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                >
                    Submit
                </button>

            </form>
        </div>
    );
};

export default InventoryForm;
