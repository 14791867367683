import React, {useEffect, useState} from 'react';
import Layout from "../../layouts/Layout";
import axios from "axios";
import RedUrl from "./utilities/RedUrl";
import {useLocation} from "react-router-dom";

export const Reports = () => {
    const [redirect, setRedirect] = useState(true);
    const {pathname} = useLocation();

    useEffect(() => {
        (async () => {
            const {data} = await axios.get(`reports/`);
        })();
    }, []);

    if (redirect) {
        const url = `https://backend.iiacinch.com/api/reports/`;
        return <RedUrl url={url}/>
    }
    return (
        <Layout>
            <div>
            </div>
        </Layout>
    );
};

export default Reports;
