import {useParams, Link} from 'react-router-dom'
import MyDataTable from "../utilities/MyDataTable";
import Empty from "../utilities/Empty";
import {format, parseJSON} from "date-fns";
import {useStockData} from "../hooks/useStockData";

const columns = [
    {
        name: 'Client',
        selector: row =>
            (
                <a className="hover:underline hover:text-blue-800 text-blue-600"
                   href={`/clients/` + row.client_id}>{row.client_name}</a>
            ),
        sortable: true,
    },
    {
        name: 'Quantity',
        selector: row => `${row.quantity} ${row.stock_units}`,
        sortable: true,
    },
    {
        name: 'Transaction Type',
        selector: row => row.transaction_type,
        sortable: true,
    },
    {
        name: 'Date & Time',
        selector: row => row.updated_at,
        format: row => format(parseJSON(row.updated_at), 'PPpp'),
        sortable: true,
    },
];


const StockCard = () => {
    const {id} = useParams();

    const {isLoading, data, isError, error} = useStockData(id);

    if (isLoading) {
        return (
            <Empty>
                Stock data is loading...
            </Empty>
        )
    }

    if (isError) {
        return (
            <Empty>
                <p className="text-red-500 hover:text-red-600 text-xl">{error.message}</p>
                <a className="text-red-300 hover:text-red-400 text-sm my-3" href="mailto:hi@iiacinch.com">hi@iiacinch.com</a>
            </Empty>
        )
    }

    return (
        <div className="bg-gray-500 py-4">
            <div
                className="shadow-lg bg-amber-100 rounded-2xl bg-white w-full mx-auto px-4 py-2 md:px-10 md:py-8 md:text-2xl max-w-2xl">
                <div className='flex justify-between align-center'>
                    <h2 className="text-2xl font-bold py-2 uppercase">{data.data.name}</h2>
                    <Link
                        to={`edit`}
                        className='px-4 py-3 rounded-xl shadow-xl bg-blue-600 hover:bg-blue-500 text-blue-100 hover:text-white font-medium text-center'
                    >
                        Edit stock
                    </Link>
                </div>
                <div className="h-full text-center my-2 text-lg font-medium divide-y">
                    <div className="flex justify-between py-2">
                        <p>Stock Name: </p>
                        <p>{data.data.name}</p>
                    </div>
                    <div className="flex justify-between py-2">
                        <p>Stock Quantity: </p>
                        <p>{data.data.quantity} {data.data.units}</p>
                    </div>
                    <div className="flex justify-between py-2">
                        <p>Stock Category: </p>
                        <p>{data.data.item_class}</p>
                    </div>
                    {/* check if stock has location and load it */}
                    {
                        data.data.location ?
                            <div className="flex justify-between py-2">
                                <p>Stock Location: </p>
                                <p>{data.data.location}</p>
                            </div>
                            :
                            <i/>
                    }
                </div>
            </div>
            <div className='py-2 '>
                {data.data.stock_transactions.length === 0 ?
                    <Empty>
                        No recorded transactions were found for this stock inventory.
                    </Empty>
                    :
                    <MyDataTable
                        columns={columns}
                        data={data.data.stock_transactions}
                        filter={'client_name'}
                        title={`${data.data.name} Transactions List`}
                    />
                }
            </div>
        </div>
    );
};

export default StockCard;
