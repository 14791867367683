import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {BASE_URL} from "../utilities/config";

const fetchStock = stockId => {
    return axios.get(`${BASE_URL}/stocks/${stockId}/`)
}

export const useStockData = (stockId) => {
    return useQuery(['stock', stockId], () => fetchStock(stockId))
}
